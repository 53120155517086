<template>
    <div class="page">
        <to-history :prop-message="title"></to-history> 
        <div id="top" class="search_wap">
            <div class="a-inline-block">
                <a-input-search v-model="sn" class="sInput" placeholder="请输入发票编号" @search="onSearch"/>
            </div>
        </div>
        
        
        <div v-if="this.pagination.total" style="text-align: right;margin-right: 20px;">当前数据条数{{this.pagination.total}}条</div>
        <div v-infinite-scroll="getInvoice" infinite-scroll-disabled="dataLoadStop" infinite-scroll-distance="50">
            <div class="lists" v-for="(item,index) in data" :key="index">
                <div class="list_row">
                    <div class="flex">
                        <div><label for="">发票编号：</label>{{item.sn}}</div>
                        <div><label for="">发票状态：</label>{{item.status}}</div>
                    </div>
                    <div class="flex">
                        <div><label for="">客户名称：</label>{{item.name}}</div>
                        <div><label for="">发票性质：</label>{{item.type}}</div>
                    </div>
                    <div class="flex">
                        <div><label for="">开票金额：</label>{{item.amount}}</div>
                        <div><label for="">开票日期：</label>{{item.sign_time}}</div>
                    </div>
                    <div class="flex">
                        <div><label for="">领取人：</label>{{item.staff_name}}</div>
                        <div><label for="">领取日期：</label>{{item.creater_time}}</div>
                    </div>
                    <div>
                        <label for="" >领取状态：</label>
                        <span v-if="item.sign == 1">未领取</span>
                        <span v-else-if="item.sign == 2">已领取</span>
                    </div>
                    <div><label for="">备注：</label>{{item.doc}}</div>
                </div>
                <div class="btn_con">
                    <a-button type="primary" size="small" ghost v-if="item.status != '作废'" @click="editInvoice(item.id)">编辑</a-button>
                    <a-popconfirm title="确定要作废吗?" v-if="item.status != '作废'" @confirm="() => off(item.id)">
                        <a-button type="danger" size="small" ghost>作废</a-button>
                    </a-popconfirm>
                    <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(item.id)">
                        <a-button type="danger" size="small" ghost>删除</a-button>
                    </a-popconfirm>
                </div>
            </div>
            <!-- <div class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>{{dataLoadNomore ? "--没有更多数据了--" : "加载中..."}}</span>
            </div> -->
            
            <a-pagination v-if="pagination.total" style="text-align: center;" @change="onChange" v-model="pagination.current" :total="pagination.total" />
            <div v-if="pagination.total==0" class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>--暂无数据--</span>
            </div>
        </div>
    </div>
</template>
<script>
// import $ from "jquery";
import infiniteScroll from "vue-infinite-scroll";
import '../../../assets/css/wapList.css';
import {requestXml,getDateTime} from '../../../assets/js/request';
export default {
    directives: {infiniteScroll},
    data() {
        return {
            // 搜索的内容
            sn: "",
            orderStatus: ["取消","新建","进行中","已完成"],
            data:[],

             // 分页
            dataLoadStop: false,  //列表数据加载停止状态
            dataLoadNomore: false,    //列表数据加载是否完成
            pagination: {
                current: 1,
                total: 100,
                pageSize: 10, // 默认每页显示数量
                showSizeChanger: true, // 显示可改变每页数量
                pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
                // showTotal: total => `Total ${total} items`, // 显示总数
                onChange: (current) => this.pagesChange(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {
                    //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize);
                },
            },
            title:this.$route.query.title
           
        };
    },
    beforeRouteEnter(to, from, next) {
      next(vm=>{
        vm.fromPath = from.path
      })
    },
    beforeDestroy(){
        sessionStorage.setItem('curpage',JSON.stringify({"sn": this.sn,'page':this.pagination}))
    },
    mounted: function(){
        if(this.fromPath == "/scrm_wap/order/invoiceAdd") {
            var data=JSON.parse(sessionStorage.getItem('curpage'))
            sessionStorage.removeItem('curpage')
            this.pagination=data.page
            this.sn=data.sn
        }
        this.getInvoice();
    },
    methods:{
        
        onChange(){
            window.location.href='#top'
            this.getInvoice();
        },
        // 获取发票列表
        getInvoice() {
            this.dataLoadStop = true;
            requestXml("/scrm/Invoice/getAllList","POST",(res) => {
                console.log(res,"resres")
                for(let i=0;i<res.list.length;i++){
                    res.list[i].sign_time = getDateTime(res.list[i].sign_time);
                    res.list[i].creater_time = getDateTime(res.list[i].creater_time);

                    if(res.list[i].type == 1){
                        res.list[i].type = "普票"
                    }else if(res.list[i].type == 2){
                        res.list[i].type = "专票"
                    }else{
                        res.list[i].type = "收据"
                    }

                    if(res.list[i].status == 1){
                        res.list[i].status = "生效"
                    }else{
                        res.list[i].status = "作废"
                    }
                    
                }
                this.data = res.list;
            //    this.data = this.data.concat(res.list);
            //     if(res.page.maxpage>this.pagination.current){
            //         this.pagination.current += 1
            //         this.dataLoadStop = false
            //     }else{
            //         this.dataLoadNomore = true
            //     }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
            },{"sn": this.sn,"page":{"curpage":this.pagination.current,"pagesize":this.pagination.pageSize}})
        },
        onSearch(){
            this.data=[];
            this.dataLoadNomore=false;
            this.pagination.current=1;
            this.getInvoice();
        },
        
        // 编辑
        editInvoice(id){
            this.$router.push({
                path: "/scrm_wap/order/invoiceAdd",
                query: {
                    id: id,
                }
            });
        },
        // 作废
        off(id){
            requestXml("/scrm/Invoice/setClose","POST",(res) => {
                if(res == "success"){
                    this.data = [];
                    this.getInvoice();
                }
            },{"id": id})
        },
        // 删除
        onDelete(id){
            requestXml("/scrm/Invoice/del","POST",(res) => {
                if(res == "success"){
                    this.data = [];
                    this.getInvoice();
                }
            },{"id": id})
        }
        
    }
};
</script>
<style>
</style>
